(function ($, app, _) {
  'use strict';

  app.m48 = function () {
    $(document).ready(function () {
      $('#m48-content .contact-panel:lt(3)').show();
      var items = $('#m48-content').children().length;
      var shown = 3;
      $('#loadMore').click(function () {
        shown = $('#m48-content .contact-panel:visible').size() + 3;
        if (shown < items) {
          $('#m48-content .contact-panel:lt(' + shown + ')').show();
        } else {
          $('#m48-content .contact-panel:lt(' + items + ')').show();
          $('#loadMore').hide();
        }
      });
    });
    $('.contact-panel:last').css({ border: 'none' });
  };
})(jQuery, app, _);
