(function ($, app, _) {
  'use strict';

  app.m01 = function () {
    var $interval = null;
    var $slickSlider = $('.homepage-slider');

    $slickSlider.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: true,
            arrows: false,
            centerMode: true,
            centerPadding: '25px',
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            dots: true,
            arrows: false,
            centerMode: true,
            centerPadding: '140px',
          },
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
    var maxHeight = -1;
    $('.homepage_slide_box').each(function () {
      if ($(this).height() > maxHeight) {
        maxHeight = $(this).height();
      }
    });
    $('.homepage_slide_box').each(function () {
      if ($(this).height() < maxHeight) {
        $(this).find('a').css('min-height', maxHeight);
      }
    });

    $slickSlider.on('afterChange', function (event, slick, currentSlide) {
      clearInterval($interval);
	  activateSlide($slickSlider.find('.slick-current'));

      startInterval();
    });

    function startInterval() {
      $interval = setInterval(() => {
        var prevSlide = $slickSlider.find('.homepage_slide.current-slide');
        if (
          prevSlide.first()[0] === $slickSlider.find('.slick-active').last()[0]
        ) {
          $slickSlider.slick('slickNext');
        }

        $slickSlider.find('.homepage_slide').removeClass('current-slide');
        var $currentItem = prevSlide.next();

        if ($currentItem.length === 0) {
          clearInterval($interval);
          return;
        }

        activateSlide($currentItem);
      }, 6000);
    }
    startInterval();

    function activateSlide($slide) {
		if ($slide.hasClass("dark-background")) {
			$slickSlider.parents(".homepage_slider").addClass("dark-background");
		} else {
			$slickSlider.parents(".homepage_slider").removeClass("dark-background");
		}
      $slickSlider.find('.homepage_slide').removeClass('is--hovered');
      $slickSlider.find('.homepage_slide').removeClass('current-slide');
      $('.m01--images img').removeClass('is--active');

      var $index = $slide.attr('data-image'),
        $title = $slide.attr('data-title'),
        $subtitle = $slide.attr('data-subtitle');

      $slide.addClass('is--hovered current-slide');
      $('.m01--image-' + $index).addClass('is--active');

      $('.homepage_slider__content h2')
        .stop()
        .animate({ opacity: 0.5 }, 400, function () {
          $(this).html($title).animate({ opacity: 1 }, 400);
        });
      $('.homepage_slider__content span')
        .stop()
        .animate({ opacity: 0.5 }, 400, function () {
          $(this).html($subtitle).animate({ opacity: 1 }, 400);
        });
    }

    $slickSlider.find('.slick-arrow').on('click', function (e) {
      clearInterval($interval);
      activateSlide($slickSlider.find('.slick-current'));
      startInterval();
    });

    $slickSlider.find('.homepage_slide').hover(
      function () {
        clearInterval($interval);
        if ($(this).hasClass('is--hovered')) {
          return;
        }

        activateSlide($(this));
      },
      function () {
        startInterval();
      }
    );
  };
})(jQuery, app, _);
