(function ($, app, _) {
  'use strict';

  app.slickSliderSetup = function () {
    $('.current-posts').on('init', function (event, slick) {
      $('.current-posts .slick-next').on('click', function (e) {
        if (slick.slideCount <= slick.currentSlide + 3) {
          $('.current-posts .slick-next').addClass('slick-disabled');
        } else if ($('.current-posts .slick-next').hasClass('slick-disabled')) {
          $('.current-posts .slick-next').removeClass('slick-disabled');
        }
      });
    });

    $('.current-posts').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: true,
      dots: false,
      infinite: false,
      autoplay: false,
      centerMode: true,
      centerPadding: '124px',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '50px',
            arrows: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            centerMode: true,
            centerPadding: '50px',
            arrows: false,
          },
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 3,
            centerMode: true,
            centerPadding: '70px',
          },
        },
      ],
    });

    $('.therapy-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.therapy-slider-nav',
    });

    $('.therapy-slider-nav').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      infinite: false,
      centerMode: true,
      centerPadding: '0',
      focusOnSelect: true,
      asNavFor: '.therapy-slider',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: false,
            arrows: false,
            centerMode: true,
            centerPadding: '50px',
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            dots: false,
            arrows: false,
            centerMode: true,
            centerPadding: '100px',
          },
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 3,
            dots: false,
          },
        },
      ],
    });
    $('.m32-slider').slick({
      variableWidth: true,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    });

    //m12-slider
    $('.m12-slider').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    //m20-slider
    $('.m20-slider').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
    });

    var maxHeight = -1;
    $('.m05_slider_box').each(function () {
      if ($(this).height() > maxHeight) {
        maxHeight = $(this).height();
      }
    });
    $('.m05_slider_box').each(function () {
      if ($(this).height() < maxHeight) {
        $(this).find('a').css('min-height', maxHeight);
      }
    });

    $('.m13-slider').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      infinite: false,
      autoplay: false,
      centerMode: true,
      variableWidth: true,
      centerPadding: '126px',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            centerMode: true,
            centerPadding: '20px',
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerMode: false,
            centerPadding: '20px',
          },
        },
      ],
    });

    //m43-slider
    $('.m43-slider').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    $('.m45-slider-nav').slick({
      variableWidth: true,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      arrows: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: true,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    });
  };
})(jQuery, app, _);
