(function ($, app, _) {
  'use strict';

  app.m09 = function () {


	  $(function() {

		  //BEGIN
		  $(".accordion__title").on("click", function(e) {

			  e.preventDefault();
			  var $this = $(this);

			  if (!$this.hasClass("accordion-active")) {
				  $(".accordion__content").slideUp(400);
				  $(".accordion__title").removeClass("accordion-active");
				  $('.accordion__arrow').removeClass('accordion__rotate');
			  }

			  $this.toggleClass("accordion-active");
			  $this.next().slideToggle();
			  $('.accordion__arrow',this).toggleClass('accordion__rotate');
		  });
		  //END

	  });


		var $slider = $(".m09__slider");
		var $navSlider = $(".m09__nav_slider");

		var slider = $slider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			swipe: false,
			swipeToSlide: false,
			fade: false,
			dots: false,
			infinite: false,
			speed: 0,
			asNavFor: ".m09__nav_slider",
			//adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						arrows: false,
						centerMode: true,
						centerPadding: "40px",
						swipe: true,
						swipeToSlide: true,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						arrows: false,
						centerMode: true,
						centerPadding: "80px",
						swipe: true,
						swipeToSlide: true,
					},
				},
				{
					breakpoint: 1240,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});

		$navSlider.on("init", function (event, slick) {
			$navSlider
				.find(".slick-track")
				.append("<span class='slick-slider-tracker'>&nbsp;</span>");
			
			var $currentSlide = slick.$slideTrack.find(".slick-slide").first(),
				$slickTracker = slick.$slideTrack.find(".slick-slider-tracker");

			$slickTracker.css({
				height: $currentSlide.find('span').innerHeight(),
			});
		});
		$navSlider.on("afterChange", function (event, slick, currentSlide) {
			var $currentSlide = slick.$slideTrack.find(".slick-slide.slick-current"),
				$slickTracker = slick.$slideTrack.find(".slick-slider-tracker"),
				topOffset = $currentSlide.offset().top - $currentSlide.parent().offset().top;

			$slickTracker.css({
				top: topOffset,
				height: $currentSlide.find('span').innerHeight(),
			});
		});

		$navSlider.slick({
			slidesToShow: 50,
			slidesToScroll: 1,
			dots: false,
			arrows: false,
			infinite: false,
			focusOnSelect: true,
			vertical: true,
			asNavFor: ".m09__slider",
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						variableWidth: true,
						dots: false,
						arrows: false,
						centerMode: true,
						vertical: false,
						swipe: true,
						swipeToSlide: true,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						variableWidth: true,
						dots: false,
						arrows: false,
						centerMode: true,
						vertical: false,
						swipe: true,
						swipeToSlide: true,
					},
				},
			],
		});

		var height = $(".m09__slider").outerHeight();
		$(".m09__slider .slick-prev").css("top", height - 72);
		$(".m09__slider .slick-next").css("top", height - 32);
  };
})(jQuery, app, _);
