(function ($, app, _) {
  'use strict';

  app.m10 = function () {
    $('.benefits-slider').on('init', function (event, slick) {
      $('.benefits-slider .slick-next').on('click', function (e) {
        var item = window.innerWidth < 768 ? 1 : 2;
        if (slick.slideCount <= slick.currentSlide + item) {
          $('.benefits-slider .slick-next').addClass('slick-disabled');
        } else if (
          $('.benefits-slider .slick-next').hasClass('slick-disabled')
        ) {
          $('.benefits-slider .slick-next').removeClass('slick-disabled');
        }
      });
    });
    $('.benefits-slider').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      // swipe: false,
      // swipeToSlide: false,
      fade: false,
      dots: false,
      infinite: false,
      row: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            swipe: true,
            dots: false,
            centerMode: true,
            centerPadding: '40px',
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            dots: false,
            swipe: true,
            centerMode: true,
            centerPadding: '100px',
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
            dots: true,
            swipe: true,
            centerMode: true,
            centerPadding: '120px',
          },
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  };
})(jQuery, app, _);
