(function ($, app, _) {
  'use strict';

  app.m29 = function () {
	var $moduleHolder = $('.m29');
	var $filters = $moduleHolder.find(".filters");
	var $postsHolder = $moduleHolder.find(".posts-holder");
	var $filtersList = $filters.find('.filters__list');
	var $submitBtn = $filters.find(".submit-filters");
	var $loadMoreBtn = $moduleHolder.find(".load-more");
	var isLoading = false;
	var formData = {
		year: [],
		category: []
	};
	var current_page = 1;
	var has_more_pages = true;
	var posts_per_page = $moduleHolder.attr("data-posts-per-page") ?? 6;

	$filters.find('[name="year"]').on('change', function () {
		if (this.checked) {
			formData.year.push(this.value);
		} else {
			formData.year.splice(formData.year.indexOf(this.value), 1);
		}
	});
	$filters.find('[name="category"]').on("change", function () {
		if (this.checked) {
			formData.category.push(this.value);
		} else {
			formData.category.splice(formData.category.indexOf(this.value), 1);
		}
	});

	$filtersList.on('click', 'span', function () {
		var type = $(this).attr("data-type");
		var value = $(this).attr("data-value");

		if (! formData[type]) {
			return;
		}
			
		formData[type].splice(formData[type].indexOf(value), 1);
		$filters.find('[name="' + type + '"][value="' + value + '"]').prop('checked', false)
		$(this).remove();
		fetchPosts();
	});

	$submitBtn.on('click', function (e) {
		e.preventDefault();
		closeModal();
	});

	$loadMoreBtn.on("click", function (e) {
		e.preventDefault();
		fetchMorePosts();
	});

    $moduleHolder.find(".filters__btn").on("click", function (e) {
		e.preventDefault();
		setTimeout(function () {
			openModal();
		});
	});

	$moduleHolder.find(".filters_modal__close").on("click", function (e) {
		e.preventDefault();
		closeModal();
	});

	// Close modal when clicking outside of modal
	app.$body.on("click", function (e) {
		if (
			$filters.hasClass("is--open") &&
			!$(e.target).closest(".m29 .filters.is--open .filters_modal").length
		) {
			closeModal();
		}
	});

	function initialFetch() {
		if ($moduleHolder.length > 0) {
			fetchPosts();
		}
	}

	initialFetch();

	function fetchPosts() {
		current_page = 1;
		has_more_pages = true;
		submitForm();
	}

	function fetchMorePosts() {
		current_page += 1;
		submitForm();
	}

	function updateFilterPills() {
		var html = '';
		formData.year.forEach(function (year) {
			html += "<span data-type='year' data-value='" + year + "'>" + year + "</span>";
		});
		formData.category.forEach(function (category) {
			html +="<span data-type='category' data-value='" + category + "'>" + $filters.find('[name="category"][value="'+ category + '"]').attr('data-name') + "</span>";
		});
		$filtersList.html(html)
	}
	
	function closeModal() {
		updateFilterPills();
		fetchPosts();
		$filters.removeClass("is--open");
	}
	
	function openModal() {
		$filters.addClass("is--open");
	}

	function showPosts(posts, append)
	{
		if (! append) {
			append = false
		}
		var html = '';

		posts.forEach(function (post) {
			html += (
				`
					<div class="column-12">
						<div class="m29_box">
							<a href="` + post.permalink + `">
								<div class="m29_box__top">
									<span>` + post.date + `</span>
									<span style="background-image: url('` + post.category_icon + `')">
										` + post.category + `
									</span>
								</div>
								<h4>
									` + post.title + `
								</h4>
								<p>
									` + post.description + `
								</p>
							</a>
						</div>
					</div>
				`
			);
		});

		if (append) {
			$postsHolder.append(html);
		} else {
			$postsHolder.html(html);
		}
	}

	function submitForm() {
		if (isLoading) {
			return;
		}

		if (! has_more_pages) {
			return;
		}

		isLoading = true;

		$.ajax({
			type: "POST",
			dataType: "json",
			url: rest_api_js_vars.wp_api_url + "/beitrage",
			data: {
				year: formData.year,
				category: formData.category,
				page: current_page,
				posts_per_page: posts_per_page
			},
			success: function (data) {
				isLoading = false;
				onPostFetch(data.posts, data.has_more_pages)
			},
			error: function (XMLHttpRequest, textStatus, errorThrown) {
				isLoading = false;
			},
		});
	}

	function onPostFetch(posts, has_more) {
		showPosts(posts, current_page > 1);

		has_more_pages = has_more;

		if (!has_more_pages) {
			$loadMoreBtn.hide();
		} else {
			$loadMoreBtn.show();
		}
	}
  };
})(jQuery, app, _);
