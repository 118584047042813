(function ($, app, _) {
  'use strict';

  app.m11 = function () {
    $('.m11-slider').on('init', function (event, slick) {
      $('.m11-slider .slick-next').on('click', function (e) {
        if (slick.slideCount <= slick.currentSlide + 4) {
          $('.m11-slider .slick-next').addClass('slick-disabled');
        } else if ($('.m11-slider .slick-next').hasClass('slick-disabled')) {
          $('.m11-slider .slick-next').removeClass('slick-disabled');
        }
      });
    });

    $('.m11-slider').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      infinite: false,
      autoplay: false,
      centerMode: true,
      variableWidth: true,
      centerPadding: '127px',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 3,
            centerMode: true,
            centerPadding: '20px',
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerMode: false,
            arrows: false,
            centerPadding: '20px',
          },
        },
      ],
    });
  };
})(jQuery, app, _);
