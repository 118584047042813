(function ($, app, _) {
  'use strict';
  app.m21 = function () {
    var $module = $('section.m21');
    var $tabs = $('.m21-tabs li');
    var $tabContent = $('.m21-tab-content');
    if ($module.length) {
      var $forms = $module.find('.gform_wrapper form');
      console.log($forms);
      $forms.each(function () {
        var id = $(this).attr('id');
        window['gravity_form_loaded_' + id] = function () {
          var $form = $module.find('.gform_wrapper form#' + id);

          $form
            .find('.ginput_container_radio')
            .parents('li')
            .addClass('gfield_container_radio_holder');
          $form
            .find('textarea')
            .parents('li')
            .addClass('gfield_container_textearea_holder');
          $form
            .find('.ginput_container_fileupload')
            .parents('li')
            .addClass('gfield_container_file_holder');
        };
      });
      // var $patientenForm = $('form#m21-therapy-request-form-patient');

      $(document).ready(function () {
        $forms.each(function () {
          window['gravity_form_loaded_' + $(this).attr('id')]();
        });
      });
    }

    $tabs.on('click', function () {
      const tabId = $(this).attr('data-tab-id');
      // remove old state
      $tabs.attr('data-active', 'false');
      $tabContent.attr('data-active', false);

      // update new state
      $(this).attr('data-active', 'true');
      $('#' + tabId).attr('data-active', 'true');
    });

    // function getFormData(form) {
    //   var formDataFD = new FormData(form);
    //   var fileInput = $(form).find("[name='files']")[0];

    //   if (fileInput) {
    //     for (var x = 0; x < fileInput.files.length; x++) {
    //       formDataFD.append('files[]', fileInput.files[x]);
    //     }
    //   }

    //   return formDataFD;
    // }

    // function submitPatientForm(e) {
    //   e.preventDefault();

    //   var $form = $(this);

    //   $form.find('button').addClass('loading');
    //   $.ajax({
    //     type: 'POST',
    //     dataType: 'json',
    //     url: rest_api_js_vars.wp_api_url + '/therapien_frage_form',
    //     data: getFormData(this),
    //     cache: false,
    //     contentType: false,
    //     processData: false,
    //     success: function (data) {
    //       $form.find('button').removeClass('loading');
    //       $form.find('#email-newsletter').val('');
    //       $('#m12').hide();
    //     },
    //     error: function (XMLHttpRequest, textStatus, errorThrown) {
    //       $form.find('button').removeClass('loading');
    //       const errors = XMLHttpRequest.responseJSON.errors;
    //       if (errors) {
    //         $form.find('.form-group').resetError();
    //         Object.keys(errors).forEach(function (key) {
    //           $form
    //             .find(".form-group [name='" + key + "']")
    //             .parent('.form-group')
    //             .addErrorMessage(errors[key]);
    //         });
    //       }
    //     },
    //   });
    // }

    // $patientenForm.on('submit', submitPatientForm);

    $('input[type="radio"]').click(function(){
      var inputValue = $(this).attr("value");
      var targetBox = $("." + inputValue);
      $(".form-form").not(targetBox).hide();
      $(targetBox).show();
    });

  };
})(jQuery, app, _);
