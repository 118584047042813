(function ($, app, _) {
  'use strict';

  app.m50 = function () {
	  $(document).ready(function () {
		  $('#m50-content .publication-panel:lt(4)').show();
		  var items = $("#m50-content").children().length;
		  items = items - 2;
		  console.log(items);
		  var shown =  3;
		  $('#m50-content .load-more').click(function () {
			  shown = $('#m50-content .publication-panel:visible').size()+3;
			  if(shown< items) {$('#m50-content .publication-panel:lt('+shown+')').show();}
			  else {$('#m50-content .publication-panel:lt('+items+')').show();
				  $('#m50-content .load-more').hide();
			  }
		  });
	  });

	  $('.publication-panel:last').css({border:'none'})

	  $(window).resize(function() {
		  if ($(window).width() < 990) {
			  $('.autoren').find('br').before(document.createTextNode(',')).remove();
		  }
	  });


  };

})(jQuery, app, _);
