/* responsive module */

(function ($, app) {
    "use strict";
    app.setCursor = function () {


        document.onmousemove = handleMouseMove;

        function handleMouseMove(event) {
            $('#circle1').addClass('showCircle');

            var eventDoc, doc, body, followerMouse;
            followerMouse = document.getElementById('followerMouse');

            event = event || window.event; // IE-ism

            // If pageX/Y aren't available and clientX/Y are,
            // calculate pageX/Y - logic taken from jQuery.
            // (This is to support old IE)
            if (event.pageX == null && event.clientX != null) {
                eventDoc = (event.target && event.target.ownerDocument) || document;
                doc = eventDoc.documentElement;
                body = eventDoc.body;

                event.pageX = event.clientX +
                    (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
                    (doc && doc.clientLeft || body && body.clientLeft || 0);
                event.pageY = event.clientY +
                    (doc && doc.scrollTop || body && body.scrollTop || 0) -
                    (doc && doc.clientTop || body && body.clientTop || 0);
            }
            // -10px me vendos ne qender kursorin kur eshte i vogel
            followerMouse.style.top = event.pageY - 10 + 'px';
            followerMouse.style.left = event.pageX - 10 + 'px';
        }

        var xMousePos = 0;
        var yMousePos = 0;
        var lastScrolledLeft = 0;
        var lastScrolledTop = 0;

        $(document).mousemove(function(event) {
            captureMousePosition(event);
        })

        $(window).scroll(function(event) {
            if(lastScrolledLeft != $(document).scrollLeft()){
                xMousePos -= lastScrolledLeft;
                lastScrolledLeft = $(document).scrollLeft();
                xMousePos += lastScrolledLeft;
            }
            if(lastScrolledTop != $(document).scrollTop()){
                yMousePos -= lastScrolledTop;
                lastScrolledTop = $(document).scrollTop();
                yMousePos += lastScrolledTop;
            }
            followerMouse.style.top = yMousePos - 10 + 'px';
            followerMouse.style.left = xMousePos - 10 + 'px';
            //$('#circle1').removeClass('cursor-link-shape').removeClass('blue-shape').removeClass('show-text').removeClass('cursor-white').empty();

        });



        function captureMousePosition(event){
            xMousePos = event.pageX;
            yMousePos = event.pageY;
            window.status = "x = " + xMousePos + " y = " + yMousePos;
        }

    };
})(jQuery, app);




