(function ($, app, _) {
  'use strict';

  app.m17 = function () {
    $('.m17__list li .m17__list__item').on('click', function (e) {
      e.preventDefault();
      $('.m17__list li.is--active').removeClass('is--active');
      $(this).parent().addClass('is--active');
    });
    $('.m17__list .number_modal__close').on('click', function (e) {
      e.preventDefault();
      $('.m17__list li.is--active').removeClass('is--active');
    });

    // Close modal when clicking outside of modal
    $('body').on('click', function (e) {
      if (!$(e.target).closest('.m17__list li.is--active').length) {
        $('.m17__list li.is--active').removeClass('is--active');
      }
    });
  };
})(jQuery, app, _);
