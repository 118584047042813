(function ($, app, _) {
  'use strict';

  app.faqAccordion = function () {
    $('.acc-left').click(function (e) {
      e.preventDefault();
      var currentAttrvalue = $(this).attr('href');
      if ($(e.target).is('.active')) {
        $(this).removeClass('active');
        $('.acc-content-left:visible').slideUp(300);
      } else {
        $('.acc-left').removeClass('active').filter(this).addClass('active');
        $('.acc-content-left')
          .slideUp(300)
          .filter(currentAttrvalue)
          .slideDown(300);
      }
    });

    $('.acc-right').click(function (e) {
      e.preventDefault();
      var currentAttrvalue = $(this).attr('href');
      if ($(e.target).is('.active')) {
        $(this).removeClass('active');
        $('.acc-content-right:visible').slideUp(300);
      } else {
        $('.acc-right').removeClass('active').filter(this).addClass('active');
        $('.acc-content-right')
          .slideUp(300)
          .filter(currentAttrvalue)
          .slideDown(300);
      }
    });
  };
})(jQuery, app, _);
