(function ($, app, _) {
  'use strict';
  app.m24 = function () {
    var topMenu = $('.m24__right'),
      topMenuHeight = 120,
      // All list items
      menuItems = topMenu.find('a'),
      // Anchors corresponding to menu items
      scrollItems = menuItems.map(function () {
        var item = $($(this).attr('href'));
        if (item.length) {
          return item;
        }
      });

    $('.m24__right a').click(function (e) {
      e.preventDefault();
      var id = $(this).attr('href');

      var scrollTo = $(id).offset().top - $('#header').outerHeight();

      $('html,body').animate(
        {
          scrollTop: scrollTo,
        },
        500
      );
    });

    // Bind to scroll
    $(window).scroll(function () {
      // Get container scroll position
      var fromTop = $(this).scrollTop() + topMenuHeight;
      // Get id of current scroll item
      var cur = scrollItems.map(function () {
        if ($(this).offset().top < fromTop) return this;
      });
      // Get the id of the current element
      cur = cur[cur.length - 1];
      var id = cur && cur.length ? cur[0].id : '';
      // Set/remove active class
      menuItems
        .parent()
        .removeClass('is--active')
        .end()
        .filter("[href='#" + id + "']")
        .parent()
        .addClass('is--active');
    });
  };
})(jQuery, app, _);
