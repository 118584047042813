(function ($, app, _) {
  'use strict';

  app.helpers = function () {
		var urlParams = new URLSearchParams(window.location.search);

		$.fn.isIE = function () {
			var ua = window.navigator.userAgent;
			var msie = ua.indexOf("MSIE ");

			if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
				// If Internet Explorer, return version number
				return true;
				// alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
			} // If another browser, return 0
			else {
				// alert("otherbrowser");
			}

			return false;
		};

		$.fn.addErrorMessage = function (error) {
			$(this).addClass("form-group--error");

			$(this).find("span.form-feedback").remove();
			$(this).append('<span class="form-feedback">' + error + "</span>");
		};

		$.fn.resetError = function () {
			$(this).removeClass("form-group--error");
			$(this).find("span.form-feedback").remove();
		};

		$.fn.toBase64 = function (file) {
			return new Promise(function (resolve, reject) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function () {
					resolve(reader.result);
				};
				reader.onerror = function (error) {
					reject(error);
				};
			});
		};
  };
})(jQuery, app, _);
