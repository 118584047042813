(function ($, app, _) {
    "use strict";

    app.player = function () {
      const controls = `
        <div class="plyr__controls">
            <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
                <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
                <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
            </button>
        </div>
      `;
      const players = Array.from(document.querySelectorAll('.js-player')).map(p => {
        const iframe = document.createElement('iframe');
        iframe.src = p.getAttribute('data-url');
        iframe.onload = () => {
          new Plyr(p, { controls })
        }
        p.appendChild(iframe);
        return new Plyr(p, { controls });
      });

      $('.fullscreen-btn').on('click', function(e){
        var currentPlayerIndex = $(this).closest('.therapy_slide').attr('data-slick-index');
        players[currentPlayerIndex].fullscreen.toggle();
      });

      $('.m03-dark-mode-btn').on('click', function(e){
        $('.m03').toggleClass('mode--dark');
      });
    };

})(jQuery, app, _);
