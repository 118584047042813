(function ($, app, _) {
    'use strict';
    app.m52 = function () {


    $('.iframe-full-height').on('load', function(){
        this.style.height=this.contentDocument.body.scrollHeight +'px';
    });





    }

    function resizeIframe(obj) {
        obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
    }

})
(jQuery, app, _);
